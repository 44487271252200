import { DeviceCommand } from "@core/types";

export const COMMAND_LIST = {
    reboot: {
        name: "Reboot",
        icon: "icon-refresh-cw",
        privilegedId: "CommandReboot"
    },
    restoreToDefault: {
        name: "Restore to Default",
        icon: "icon-corner-down-left",
        privilegedId: "CommandRestore"
    },
    upgradeFirmware: {
        name: "Upgrade Firmware",
        icon: "icon-upload",
        privilegedId: "CommandUpgradeFw"
    },
    upgradeConfiguration: {
        name: "Upgrade Configuration",
        icon: "icon-upload",
        privilegedId: "CommandUpgradeCfg"
    },
    backupConfiguration: {
        name: "Backup Configuration",
        icon: "icon-copy",
        privilegedId: "CommandBackupCfg"
    },
    diagnosticTool: {
        name: "Diagnostic Tool",
        icon: "icon-activity",
        privilegedId: "CommandDiagnostic"
    },
    /* udpTest: {
        name: "UDP Testing",
        icon: "icon-shuffle"
    }, */
    cbsd: {
        name: "CBSD",
        icon: "icon-pocket",
        privilegedId: "CommandCBSD",
        children: [
            {
                key: "cbsdDeregister",
                name: "Deregister",
                icon: "icon-link-2",
                privilegedId: "CommandCBSD"
            },
            {
                key: "cbsdRelinquishment",
                name: "Relinquishment",
                icon: "icon-scissors",
                privilegedId: "CommandCBSD"
            },
            {
                key: "cbsdGrantRenew",
                name: "Grant Renew",
                icon: "icon-log-in",
                privilegedId: "CommandCBSD"
            },
            {
                key: "cbsdCpiPrivateKey",
                name: "CPI Private Key",
                icon: "icon-lock",
                privilegedId: "CommandCBSD"
            }
        ]
    }
};

export const CBSD_COMMAND: { [key: string]: DeviceCommand.CBSD_DEREGISTER | DeviceCommand.CBSD_RELINQUISHMENT | DeviceCommand.CBSD_GRANT_RENEW } = {
    cbsdDeregister: DeviceCommand.CBSD_DEREGISTER,
    cbsdRelinquishment: DeviceCommand.CBSD_RELINQUISHMENT,
    cbsdGrantRenew: DeviceCommand.CBSD_GRANT_RENEW
};