import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import UserAgent from 'user-agents';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

	/**
	 * Append "Authorization" token in headers if logged in
	 * 
	 * @param {HttpRequest<any>} request
	 * @param {HttpHandler} next
	 * @returns {Observable<HttpEvent<any>>}
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers = {};
		if (sessionStorage.getItem("becentralAccessToken")) {
			// access token
			headers['Authorization'] = `Bearer ${sessionStorage.getItem("becentralAccessToken")}`;

			// user agent
			// const userAgent = new UserAgent();
			// headers['user-agent-platform'] = JSON.stringify(userAgent.data);
		}
		const newRequest = request.clone({ setHeaders: headers });
		return next.handle(newRequest);
	}
}

export const AccessTokenAppendToHeaderProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: AccessTokenInterceptor,
	multi: true
};