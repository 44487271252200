<div [ngClass]="containerClass">
    <label [ngClass]="labelClass" [for]="id">{{label}}</label>
    <div [ngClass]="inputClass">
        <div class="input-group input-group-merge" [ngClass]="{ 'is-invalid': invalid }">
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': invalid }" [id]="id" [(ngModel)]='value'
                [placeholder]="placeholder" [disabled]="isDisabled" (blur)="onTouch()" [autocomplete]="'new-' + id"
                [maxlength]="maxlength" (focus)="onFocus()" readonly>
            <!-- readonly: avoid autofill in browser; remover when focus -->
        </div>
        <div *ngIf="invalid && displayErrorMessage" class="invalid-feedback">
            <p *ngIf="isRequired && errors?.required">{{label}} is required</p>
            <p *ngIf="isEmail && errors?.email">Email format is incorrect</p>
            <p *ngIf="errors?.requiredIpFormat">IP format is incorrect</p>
            <p *ngIf="errors?.requiredDhcpLeaseTimeFormat">Leasetime format is incorrect</p>
            <p *ngIf="errors?.url">Url format is incorrect</p>
            <p *ngIf="errors?.speedTestUrl">
                Url format is incorrect (ex. www.speedtest.myserver.com:8080)</p>
            <p *ngIf="errors?.emailLoop">
                Email loop format is incorrect (ex. john.doe&#64;gmail.com;jane.doe&#64;hotmail.com;)</p>
            <p *ngIf="errors?.maxlength">Maximum char is {{errors?.maxlength.requiredLength}} (you type:
                {{errors?.maxlength.actualLength}} )</p>
            <p *ngIf="errors?.key">Pre-Shared Key's format is incorrect</p>
            <p *ngIf="errors?.custIdFormat">Only allow Letters and Numbers</p>
        </div>
        <p class="mb-0" *ngIf="hint"><small class="text-muted">{{hint}}</small>
    </div>
</div>