import { AfterViewInit, Directive, ElementRef, Input, OnInit } from "@angular/core";
import { HtmlEvents } from "@core/types";

@Directive({
    selector: '[disableEventPassing]'
})
export class DisableEventPassing implements AfterViewInit {

    // Public
    @Input('disableEventPassing') event: HtmlEvents;
    @Input() isCaptured: boolean = false;

    // Private
    private _nativeElement: HTMLElement = {} as HTMLElement;

    /**
     * Constructor
     *
     * @param {ElementRef} _elementRef
     */
    constructor(private _elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        // Get the native element
        this._nativeElement = this._elementRef.nativeElement;

        // Add Event blocker
        this._nativeElement.addEventListener(this.event, (e) => { e.stopPropagation(); }, this.isCaptured)
    }
}