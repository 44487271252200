/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { UploadUrlRequestBody, NoticedBackendRequestBody, FileTransferingProgress, FileStage, ShareFileRequestBody } from '@core/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

@Injectable()
export class Files extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /files - get files list
     * 
     * @param filter
     */
    public getFiles(filter) {
        return this.find("files", filter);
    }

    /**
     * GET /files - get files list
     * 
     * @param {string} id
     */
    public getFileById(id) {
        return this.findById("files", id);
    }

    /**
     * POST /files/upload-url - get upload url for file
     * 
     * @param {UploadUrlRequestBody} data
     */
    public getUploadUrl(data: UploadUrlRequestBody) {

        let method = "POST",
            url = "files/upload-url",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PUT {uploadUrl} - upload file directlly to Digital Ocean
     * 
     * @param {string} uploadUrl 
     * @param {File} file 
     * @param {string} contentType 
     */
    public uploadFile(uploadUrl: string, file: File, contentType: string = "application/json"): BehaviorSubject<FileTransferingProgress> {
        const request = new XMLHttpRequest();
        let uploadingProgress = new BehaviorSubject<FileTransferingProgress>({ progress: 0, state: FileStage.IN_PROGRESS })
        request.upload.addEventListener('progress', (e) => {
            let file1Size = file.size;
            if (e.loaded <= file1Size) {
                let percent = Math.round(e.loaded / file1Size * 100);
                uploadingProgress.next({ progress: percent, state: FileStage.IN_PROGRESS });
            }

            if (e.loaded == e.total) {
                uploadingProgress.next({ progress: 100, state: FileStage.IN_PROGRESS });
            }
        });
        request.onload = () => {
            uploadingProgress.complete();
        };
        request.onerror = () => {
            uploadingProgress.next({ progress: 0, state: FileStage.ERROR });
            console.log("Upload File Error...");
        };
        request.ontimeout = () => {
            uploadingProgress.next({ progress: 0, state: FileStage.ERROR });
            console.log("Upload File Timeout...");
        }
        request.open('PUT', uploadUrl);
        request.timeout = 600000;
        request.setRequestHeader("Content-Type", contentType);
        request.send(file);
        return uploadingProgress;
    }

    /**
     * POST /files - notice backend file got uploaded
     * 
     * @param {NoticedBackendRequestBody} data 
     */
    public uploadFileNoticBackend(data: NoticedBackendRequestBody) {

        let method = "POST",
            url = "files",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /files/{id}/download - get download url
     * 
     * @param id 
     * @returns 
     */
    public getDownloadUrl(id: string) {

        let method = "GET",
            url = "files/:id/download",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /files/{id} - delete the file by id
     * 
     * @param {string} id
     */
    public deleteFile(id: string) {

        let method = "DELETE",
            url = "files/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /files/{id}/share - share the file by id
     * 
     * @param {ShareFileRequestBody} data 
     */
    public shareFile(id: string, data: ShareFileRequestBody) {

        let method = "POST",
            url = "files/:id/share",
            routeParams = { id },
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PUT /files/bulk-update-pci - upload bulk update pci info
     * 
     * @param {any} data form data
     */
    public bulkUpdatePci(data: any) {

        let method = "PUT_PROGRESS",
            url = "files/bulk-update-pci",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PUT /files/bulk-update-device-info - upload bulk update pci info
     * 
     * @param {any} data form data
     */
    public bulkUpdateDeviceInfo(data: any) {

        let method = "PUT_PROGRESS",
            url = "files/bulk-update-device-info",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PUT /files/bulk-activate-devices - upload MAC file to bulk activated mqtt user
     * 
     * @param {any} data form data
     */
    public bulkActivate(data: any) {

        let method = "PUT_PROGRESS",
            url = "files/bulk-activate-devices",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}