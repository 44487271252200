/******* Angular Resourse *******/
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient } from '@angular/common/http';
import { ScheduleActionRequestBody, ScheduleFilters, SchedulerRequestBody, ScheduleSummaryFilters } from '@core/types';

@Injectable()
export class Schedules extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }

    /**
     * GET /Schedules - get Schedule list
     * 
     * @param {ScheduleFilters} scheduleFilters
     */
    public getSchedules(scheduleFilters: ScheduleFilters) {
        let method = "GET",
            url = "Schedules",
            routeParams = {},
            urlParams = {
                pageIndex: scheduleFilters.pageIndex,
                pageSize: scheduleFilters.pageSize,
                filters: JSON.stringify(scheduleFilters.filters),
                sorts: JSON.stringify(scheduleFilters.sorts)
            },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /Schedules - create the task
     * 
     * @param {SchedulerRequestBody} data
     */
    public createTask(data: SchedulerRequestBody) {
        let method = "POST",
            url = "Schedules",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * PUT /Schedules - update the task
     * 
     * @param {SchedulerRequestBody} data
     */
    public updateTask(data: SchedulerRequestBody) {
        let method = "PUT",
            url = "Schedules",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /Schedules/{id} - get the task by id
     * 
     * @param {string} id
     */
    public getSingleTask(id: string) {
        let method = "GET",
            url = "Schedules/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /Schedules/{id}/devices - get the task's device by id
     * 
     * @param {string} id
     */
    public getDeviceInSingleTask(id: string) {
        let method = "GET",
            url = "Schedules/:id/devices",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * DELETE /Schedules/{id} - delete the task by id
     * 
     * @param {string} id
     */
    public deleteTask(id: string) {
        let method = "DELETE",
            url = "Schedules/:id",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /Schedules/status-summary - get status of all tasks
     * 
     * @param {ScheduleSummaryFilters} scheduleSummaryFilters
     */
    public getTaskStatusSummay(scheduleSummaryFilters: ScheduleSummaryFilters = {}) {
        let method = "GET",
            url = "Schedules/status-summary",
            routeParams = {},
            urlParams = { ...scheduleSummaryFilters },
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /Schedules/retry - retry the task
     * 
     * @param {ScheduleActionRequestBody} data
     */
    public retryTask(data: ScheduleActionRequestBody) {
        let method = "POST",
            url = "Schedules/retry",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * POST /Schedules/run-now - force start the task
     * 
     * @param {ScheduleActionRequestBody} data
     */
    public runNowTask(data: ScheduleActionRequestBody) {
        let method = "POST",
            url = "Schedules/run-now",
            routeParams = {},
            urlParams = {},
            postBody = data;

        return this.request(method, url, routeParams, urlParams, postBody);
    }

    /**
     * GET /Schedules/export - generate task results
     * 
     * @param {string} id
     */
    public exportTaskResults(id: string) {
        let method = "GET_FILE",
            url = "Schedules/:id/export",
            routeParams = { id },
            urlParams = {},
            postBody = {};

        return this.request(method, url, routeParams, urlParams, postBody);
    }
}