/******* Angular Resourse *******/
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '@api/base/base.service';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { UploadUrlRequestBody, NoticedBackendRequestBody, FileTransferingProgress, FileStage, ShareFileRequestBody } from '@core/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

@Injectable()
export class MqttUsers extends BaseApi {

    constructor(@Inject(HttpClient) protected http: HttpClient) {
        super(http);
    }
}