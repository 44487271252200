import { DeviceFeatures, ModelFeaturesMap, SupportDeviceModel } from "@core/types";

export const MODEL_FEATURES_MAP: ModelFeaturesMap = {
    [SupportDeviceModel.M_100]: [
        DeviceFeatures.GPS,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.M_120_N]: [
        DeviceFeatures.GPS,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.M_150]: [
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.M_300]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.OPENWRT_DEVICE,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel.M_300AX]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.OPENWRT_DEVICE,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel.M_500]: [
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.M_600]: [
        DeviceFeatures.GPS,
        DeviceFeatures._5G_NR,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.WIRELESS_CLIENT,
        DeviceFeatures.DUAL_SIM_FAILOVER,
        DeviceFeatures.DUAL_SIM,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.M_700]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.OPENWRT_DEVICE,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],

    /////////////////// Overlap Model Here
    [SupportDeviceModel.MX_200]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.MX_200E]: [
        DeviceFeatures.MX,
        DeviceFeatures.E_SERIES,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.MX_200A]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.MX_200AE]: [
        DeviceFeatures.MX,
        DeviceFeatures.E_SERIES,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    /////////////////// Overlap Model End

    [SupportDeviceModel.MX_210_NP]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.WIRELESS_CLIENT,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.LAN_DESCRIPTION_LAN2,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.EG_210_N]: [
        DeviceFeatures.GPS,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.LAN_DESCRIPTION_LAN2,
        DeviceFeatures.FTP
    ],
    /////////////////// Overlap Model Start
    [SupportDeviceModel.MX_220]: [
        DeviceFeatures.MX,
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures.SECOND_EWAN_BRIDGE_MODE,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.DUAL_SIM_FAILOVER,
        DeviceFeatures.DUAL_SIM,
        DeviceFeatures.VPN,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.MX_220_UT]: [
        DeviceFeatures.MX,
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures.SECOND_EWAN_BRIDGE_MODE,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.DUAL_SIM_FAILOVER,
        DeviceFeatures.DUAL_SIM,
        DeviceFeatures.VPN,
        DeviceFeatures.UTILITY,
        DeviceFeatures.GPS,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    /////////////////// Overlap Model End
    [SupportDeviceModel.MX_221_P]: [
        DeviceFeatures.MX,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN2,
        DeviceFeatures.TRIPLE_SIM,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.MX_230]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.MX_240]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures._5G_NR, // new modified
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.MX_241_NP]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.WIRELESS_CLIENT,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.LAN_DESCRIPTION_LAN2,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.MX_250]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures._5G_NR, // new modified
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.MX_250E]: [
        DeviceFeatures.MX,
        DeviceFeatures.E_SERIES,
        DeviceFeatures.GPS,
        DeviceFeatures._5G_NR, // new modified
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel.MX_600]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures.SECOND_EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.WIRELESS_CLIENT,
        DeviceFeatures.DUAL_SIM_FAILOVER,
        DeviceFeatures.HARDWARE_MONITOR,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.DUAL_SIM,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.MX_1000]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.SECOND_4G_LTE,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.HARDWARE_MONITOR,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.MX_1000E]: [
        DeviceFeatures.MX,
        DeviceFeatures.E_SERIES,
        DeviceFeatures.GPS,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.SECOND_4G_LTE,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.HARDWARE_MONITOR,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel.MX_1200]: [
        DeviceFeatures.MX,
        DeviceFeatures.GPS,
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.SECOND_EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.SECOND_4G_LTE,
        DeviceFeatures.WIRELESS_CLIENT,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_2,
        DeviceFeatures.HARDWARE_MONITOR,
        DeviceFeatures.DUAL_SIM_FAILOVER,
        DeviceFeatures.LAN_DESCRIPTION_LAN1,
        DeviceFeatures.DUAL_SIM,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],

    /////////////////// Overlap Model Here
    [SupportDeviceModel._4500]: [
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel._4500AZ]: [
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel._4500NZ]: [
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    /////////////////// Overlap Model End
    [SupportDeviceModel._4520AZ]: [
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_SIM,
        DeviceFeatures.DUAL_SIM_FAILOVER,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    /////////////////// Overlap Model Here
    [SupportDeviceModel._4700]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.LAN_DESCRIPTION_NONE,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._4700AZ]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.LAN_DESCRIPTION_NONE,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel._4700ZU]: [
        DeviceFeatures.OUTDOOR_UNIT,
        // DeviceFeatures._5G_NR,
        // DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        // DeviceFeatures.WI_FI,
        // DeviceFeatures._5G_WI_FI,
        // DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.LAN_DESCRIPTION_NONE,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP
    ],
    /////////////////// Overlap Model End

    [SupportDeviceModel._4900]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._6200]: [
        DeviceFeatures.WZL,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING
    ],
    [SupportDeviceModel._6300VNL]: [
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures.SECOND_EWAN_BRIDGE_MODE,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.LAN_DESCRIPTION_NONE,
        DeviceFeatures.VOIP,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN
    ],
    [SupportDeviceModel._6300NEL]: [
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures.SECOND_EWAN_BRIDGE_MODE,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.LAN_DESCRIPTION_NONE,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN
    ],
    /////////////////// Overlap Model Here
    [SupportDeviceModel._6500]: [
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel._6500AL]: [
        DeviceFeatures.WIRELINE_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    /////////////////// Overlap Model End
    [SupportDeviceModel._6600]: [
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_FAILOVER_AND_PRIORITY,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel._6800]: [
        DeviceFeatures.RUL,
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._6900]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._7000]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._7000_R26_W]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_2,
        DeviceFeatures.LAN_DESCRIPTION_NONE,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._8110]: [
        DeviceFeatures._5G_NR,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.WIRELESS_CLIENT,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel._8112]: [
        DeviceFeatures._5G_NR,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.MULTIPLE_WAN,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.WIRELESS_CLIENT,
        DeviceFeatures.STATION_NUMBER,
        DeviceFeatures.WI_FI_ENTERPRISE_SECURITY_TYPE,
        DeviceFeatures.VPN,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ],
    [SupportDeviceModel._8230]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._8231]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._8232]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._8235]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.OPENWRT_DEVICE,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    /////////////////// Overlap Model Here
    [SupportDeviceModel._8355]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.OPENWRT_DEVICE,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._8355P]: [
        DeviceFeatures.OUTDOOR_UNIT,
        DeviceFeatures._5G_NR,
        DeviceFeatures.WIRELESS_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures._5G_NR_CONFIG,
        DeviceFeatures.OPENWRT_DEVICE,
        DeviceFeatures.VPN,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._8115]: [
        DeviceFeatures.OUT_SOURCING_DEVICE
    ],
    /////////////////// Overlap Model End
    [SupportDeviceModel._8920]: [
        DeviceFeatures.WIRELINE_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures.EWAN,
        DeviceFeatures._4G_LTE,
        DeviceFeatures.PCI_LOCK,
        DeviceFeatures.PHYSICAL_PORTS,
        DeviceFeatures.PHYSICAL_PORTS_LAN_1,
        DeviceFeatures.PHYSICAL_PORTS_LAN_2,
        DeviceFeatures.PHYSICAL_PORTS_LAN_3,
        DeviceFeatures.PHYSICAL_PORTS_LAN_4,
        DeviceFeatures.PHYSICAL_PORTS_WLAN_1,
        DeviceFeatures.FTP
    ],
    [SupportDeviceModel._9900]: [
        DeviceFeatures.WIRELINE_DEVICES,
        DeviceFeatures.WI_FI,
        DeviceFeatures._5G_WI_FI,
        DeviceFeatures.EWAN_MULTIPLE_SERVICE,
        DeviceFeatures.EWAN_BANDWIDTH,
        DeviceFeatures.SECOND_EWAN_BRIDGE_MODE,
        DeviceFeatures.VPN,
        DeviceFeatures.VOIP,
        DeviceFeatures.HOTSPOT,
        DeviceFeatures.FTP,
        DeviceFeatures.DUAL_WAN,
        DeviceFeatures.DUAL_WAN_OUT_BOUND_LOAD_BALANCE,
        DeviceFeatures.DUAL_WAN_PROBE_BY_SIGNAL_STRENGTH,
        DeviceFeatures.DUAL_WAN_PROTOCOL_BINDING,
        DeviceFeatures.DUAL_WAN_SMART_WIFI_CONTROLLER
    ]
};