export interface ServerToClientEvents {
    // Topic: callback
    cbsd_status: () => void
};

export interface ClientToServerEvents {
    // Topic: callback
    request: (requestData: any, callback: (arg: any) => void) => void
};

export enum MessageType {
    ALL = "ALL",
    DEVICE = "Device",
    DEVICE_ALARM = "DeviceAlarm",
    DEVICE_CONNECTION = "DeviceConnection",
    SChEDULER_STATUS = "Scheduler:Status",
    REPORT_STATUS = "ReportScheduler:Status",
    LICENSES_PERCENTAGE = "LicensesPercentage",
    RF_LIVE_INFO = "rf_info",
    CBSD_STATUS = "CBSD_STATUS"
};

export interface MessageData {
    eventName: string;
    type: MessageType;
    data?: any;
};