import packageJson from '../../package.json';

export const environment = {
	production: true,
	hmr: false,
	apiUrl: "/api/accesses/",
	userServiceUrl: "/api/accesses/",
	deviceGatewayUrl: "/api/devices/",
	scheduleGatewayUrl: "/api/schedulers/api/",
	socketUrl: "/api/accesses",
	rttysGatewayUrl: "https://rttys.becentral.io/web/",
	recaptchaSiteKey: "6LcCeRYoAAAAAHVEb-V0ht4cbYdJnnpcr-t0nIWA",
	becentralApiDocumentUrl: "https://docs.becentral.io/api",
	internetFunction: true,
	versionControl: packageJson.version,
	CLIENT_SECRET: "BECentralAsia",
	CLIENT_ID: "BECentral-Client"
}
